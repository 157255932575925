<template>
  <div :style="format" id="soalPdf">
    <div id="headerPdf" class="mx-5">
      <div class="d-flex justify-content-between align-items-center mt-4">
        <div>
          <img
            :src="institusi ? institusi.logo : '/Logo-Hasan.png'"
            width="100px"
            alt
          />
        </div>
        <div class="text-center ml-4">
          <h3>{{ institusi ? institusi.nama.toUpperCase() : "-" }}</h3>
          <div>{{ institusi.alamat ? institusi.alamat.lokasi : "-" }}</div>
          <div>E-Mail : {{ institusi ? institusi.email : "-" }}</div>
        </div>
        <div>
          <img
            src="@/assets/dokumen.png"
            width="150px"
            height="70px"
            alt
          />
        </div>
      </div>
      <hr style="border: 1px solid black" />
    </div>
    <div class="mx-5" id="codeOfConduct">
      <h3 class="text-center">
        {{ detailCbt.tipePanjang }} ({{ detailCbt.tipe }})
      </h3>
      <h3 class="text-center">
        SEMESTER {{ semester }} -
        TAHUN PELAJARAN {{ tahun }}
      </h3>
      <table border="0" class="mt-3 font-size-14">
        <tr>
          <td>Hari, Tanggal</td>
          <td class="px-4">:</td>
          <td>{{ tanggalUjian }}</td>
        </tr>
        <tr>
          <td width="45%">Mata Pelajaran</td>
          <td class="px-4">:</td>
          <td>{{ namaMapel }}</td>
        </tr>
        <tr>
          <td>Kelas</td>
          <td class="px-4">:</td>
          <td>{{ kelas }}</td>
        </tr>
        <tr>
          <td>Durasi</td>
          <td class="px-4">:</td>
          <td>{{ detailCbt.durasi }} (menit)</td>
        </tr>
      </table>
      <hr style="border: 1px solid black" />
      <span id="instruksiPdf" v-html="detailCbt.instruksi"></span>
      <hr style="border: 1px solid black" />
    </div>
    <div
      v-for="(item, i) in dataSoal"
      :key="i"
      class="mx-5 content"
      style="margin-bottom: 20px"
    >
      <div class="d-flex flex-row">
        <div class="font-weight-bold">{{ numberQuestion(i) }}.</div>
        <div class="ml-2">
          <span class="soal" ref="soal" v-html="item.soal"></span>
          <template v-if="item.tipe_soal === 'Multiple Choice'">
            <div
              class="mt-2 d-flex"
              v-for="(itemJawaban, j) in item.jawaban"
              :key="j"
            >
              <div>{{String.fromCharCode(65 + j)}}.&nbsp;</div>
              <span class="soal" ref="jawaban" v-html="itemJawaban"></span>
            </div>
            <div class="mt-3 font-weight-bold">
              <span
                v-if="item.tipe_soal === 'Multiple Choice'"
                class="mr-4 text-bold"
                >Kunci Jawaban : {{ item.kunci_jawaban }}</span
              >
              <span class="mr-4 text-bold"
                >Bobot Soal : {{ item.bobot_soal }}</span
              >
              <span class="mr-4 text-bold"
                >Kriteria : {{ item.kriteria }}</span
              >
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { renderMathMl } from '@/helpers/mathjax'
export default {
  data() {
    return {}
  },
  props: ['detailCbt', 'format', 'kelas', 'tanggalUjian', 'dataSoal'],
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
    semester() {
      return this.detailCbt.tahun_ajaran?.semester.toUpperCase()
    },
    tahun() {
      return this.detailCbt.tahun_ajaran?.tahun
    },
    namaMapel() {
      return this.detailCbt.mata_pelajaran?.nama
    },
  },
  methods: {
    numberQuestion(index) {
      return ++index
    },
  },
  mounted() {
    let arrayOfRef = []
    const soal = this.$refs.soal
    const jawaban = this.$refs.jawaban
    if (soal || jawaban) {
      if (soal) {
        if (Array.isArray(soal)) arrayOfRef = arrayOfRef.concat(soal)
        else arrayOfRef.push(soal)
      }

      if (jawaban) {
        if (Array.isArray(jawaban)) arrayOfRef = arrayOfRef.concat(jawaban)
        else arrayOfRef.push(jawaban)
      }

      renderMathMl(arrayOfRef)
    }
  },
}
</script>
